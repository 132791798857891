
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Table } from "@/utils/decorator";
import { emptyToLine, splitThousands, getTestPort } from "@/utils/common";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { formDownload } from "@/utils/tools";
import t from "@common/src/i18n/t";
const warehouse = namespace("warehouse");
const parts = namespace("parts");
const base = namespace("base");
const inventory = namespace("inventory");
const partsCategory = namespace("parts-category");
const  management = namespace("management");

@Component({
  components: { TableEnableFlag }
})
@Table("loadListData")
export default class Parts extends Vue {
  @inventory.Action getPartsInventoryList;
  @parts.Action updateStatus;
  @partsCategory.Action getCategoryList;
  @base.Action getDictionaryList;
  @parts.Action getPartsBrandList;
  @warehouse.Action getWarehouseList;
  @management.Action getStoreList;
  searchForm = {
    partsName: "",
    warehouseCode: "",
    partsCategoryCode: "",
    brandCode: "",
    storeCode: "",
    storeName: "",
  };
  hideCost: boolean = true;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  sortForm: {};
  partsList: any[] = [];
  selLoading: boolean = false;
  get searchList() {
    return [
      {
        label: "parts.name",
        type: "input",
        value: "",
        prop: "partsName"
      },
      {
        label: t("setting.brand"),
        type: "select",
        value: "",
        prop: "brandCode",
        selectList: this.brandList
      },

      {
        label: "parts.category",
        type: "cascader",
        prop: "partsCategoryCode",
        value: "",
        options: this.categoryList,
        props: {
          label: "categoryName",
          value: "categoryCode"
        }
      },
      {
        label: "warehouse.warehouse",
        type: "select",
        value: "",
        selectList: this.warehouseList,
        prop: "warehouseCode"
      }
    ];
  }
  categoryList: any[] = [];
  warehouseList: any = [];
  brandList: any = [];
  storeList: any = [];
  created() {
    this.init();
  }
  handleExportData() {
    formDownload(`${getTestPort()}/inventory/qtyOpExport`, this.searchForm);
  }
  async init() {
    this.selLoading = true;
    this.getStoreList({
      pageNum: 1,
      pageSize: 50,
      storeName: "",
      storeTypeList: ["3", "8", "7","9"]
    }).then(res => {
      this.selLoading = false;
      this.storeList = res.data.list || [];

      this.searchForm.storeCode = this.storeList[0].storeCode;
      this.searchForm.storeName = this.storeList[0].storeName
      this.initOther();
    })

  }

  async initOther(){
    this.loadListData();
    this.getBrandList();
    this.getWarehouseList({
      pageNum: 1,
      pageSize: 100,
      storeCode: this.searchForm.storeCode
    }).then(data => {
      this.warehouseList = this.$safeValue(data, "data.list", []).map(item => {
        (item.label = item.warehouseName), (item.value = item.warehouseCode);
        return item;
      });

    });
    const data = await this.getCategoryList({storeCode: this.searchForm.storeCode});
    this.categoryList = this.addLeafFlag(this.$safeValue(data, "data", []));
  }

  handleLinkPartsDetail(row) {
    let { href } = this.$router.resolve({
      path: `/setting/parts-edit/${row.partsCode}`
    });
    window.open(href, "_blank");
  }
  getBrandList() {
    this.getPartsBrandList().then(data => {
      this.brandList = this.$safeValue(data, "data.list", []).map(item => ({
        label: item.brandName,
        value: item.brandCode
      }));
    });
  }
  inventoryQty: any = "";
  inventoryAmount: any = "";
  @Table.LoadDataMethod()
  loadListData() {
    return this.getPartsInventoryList({
      // status: "9",
      orderItems: this.mix_sortParams,
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize,
      partsName: this.searchForm.partsName
    }).then(data => {
      this.partsList = this.$safeValue(data, "data.list", []);
      this.inventoryQty = data.data.inventoryQty;
      this.inventoryAmount = data.data.inventoryAmount;
      if(this.partsList && this.partsList.length > 0){
        this.partsList.map(item => {
          item.storeName = this.searchForm.storeName;
          return item;
        })
      }
      return data;
    });
  }
  addLeafFlag(list) {
    if (!list) return;
    const f = function(list) {
      list.forEach(item => {
        if (!item.children || item.children.length < 1) {
          delete item.children;
        } else {
          f(item.children);
        }
      });
    };
    f(list);
    return list;
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "partsCategoryCode") {
          this.searchForm[item.prop] = item.value[item.value.length - 1] || "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }

  searchStore(val = "") {
    this.selLoading = true;
    this.getStoreList({
      pageNum: 1,
      pageSize: 50,
      storeName: val,
      storeTypeList: ["3", "8", "7","9"]
    }).then(res => {
      this.selLoading = false;
      this.storeList = res.data.list || [];
    })
  }

  handleStoreChange(val){
    this.searchForm.storeCode = val;
    const storeInfo = this.storeList.filter(item=> item.storeCode === val)
    if(storeInfo){
      this.searchForm.storeName = storeInfo[0].storeName
    }
    this.initOther();
  }
}
